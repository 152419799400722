import UserContext from "@context/User/UserContext";
import { faArrowLeft, faChartBar, faCheckCircle, faList, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLeccionesVistas } from "@helpers/httpRequests/httpRequestLeccionesVistas/httpRequestsLeccionesVistas";
import { getUsuariosByIdGrupo } from "@helpers/httpRequests/httpRequestsGrupos/httpRequestsGrupos";
import { getLeccionByCodigo, getLeccionesByCodigoCurso, getUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { Checkbox } from "primereact/checkbox";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tab, Tabs } from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import ListadoCursos from "../Cursos/ListadoCursos";
import { DataTablesRecipient } from "../DataTablesRecipient/DataTablesRecipient";
import DynamicBreadcrumb from "../DynamicBreadcrumb/DynamicBreadcrumb";
import TitleDashboard from "../Titles/TitleDashboard";
import columns from "./datatables/columns";
import columnsEmpleados from "./datatables/columnsEmpleados";

import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import useWindowResize from "@customHooks/useWindowResize";
import { useRef } from "react";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { getCalificacionPorCurso } from "@helpers/httpRequests/httpRequestsCursosUsuario/httpRequestsCursosUsuario";
import { verificarCertificado } from "@helpers/httpRequests/httpRequestsCertificadosEmitidos/httpRequestsCertificadosEmitidos";
import { getTiempoPlataforma } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import moment from "moment";
import ListarAvanceEmpleado from "./components/ListarAvanceEmpleado";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import getFechaSinHora from "@helpers/dates/getDateFinalizacionCurso/getFechaSinHora";
import { getLeccionByCodigoIngles } from "@helpers/httpRequests/httpRequestCursoIngles/httpRequestCursoIngles";

const MisAvances = ({ handleChangeComponent }) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { idPerfil, rol, idGrupo, fechaUltimoAcceso, tiempoEnPlataforma, modoOscuro, idUsuarioEmpresarial, idPersona } = usuarioLogeado;

	const [cursos, setCursos] = useState(JSON.parse(usuarioLogeado.cursos));

	//*STATE PARA LECCIONES VISTAS DE MANERA INDIVIDUAL
	const [leccionesVistas, setLeccionesVistas] = useState([]);

	const [avancePorCurso, setAvancePorCurso] = useState([]);

	//*STATE PARA LAS LECCIONES VISTAS POR EL EMPLEADO SELECCIONADO
	const [leccionesVistasEmpleado, setLeccionesVistasEmpleado] = useState([]);

	// const [avancePorCursoEmpleado, setAvancePorCursoEmpleado] = useState([]);

	const [leccionesListadas, setLeccionesListadas] = useState(false);

	const [leccionesSeleccionadas, setLeccionesSeleccionadas] = useState([]);

	const [leccionesSeleccionadasEmpleado, setLeccionesSeleccionadasEmpleado] = useState([]);

	const [dataTableColumns, setDataTableColumns] = useState([]);

	const [dataTableColumnsEmpleados, setDataTableColumnsEmpleados] = useState([]);

	//*STATE PARA EL AVANCE INDIVIDUAL
	const [mostrarLecciones, setMostrarLecciones] = useState(false);

	//*STATE PARA EL AVANCE DE CADA USUARIO
	const [leccionesListadasEmpleado, setLeccionesListadasEmpleado] = useState(false);

	const [key, setKey] = useState("avance-individual");

	const [empleados, setEmpleados] = useState([]);

	const breadcrumbItemsInitialState = [{ title: "Mis avances", onClick: () => {} }];

	// const [leccionesTomadas, setLeccionesTomadas] = useState(0);

	// const [leccionesTotales, setLeccionesTotales] = useState(0);

	const [ultimaLeccionTomada, setUltimaLeccionTomada] = useState(null);

	// const [calificacionCurso, setCalificacionCurso] = useState(0);

	const [puntajeCertificacion, setPuntajeCertificacion] = useState(0);

	const [yearsPlataforma, setYearsPlataforma] = useState(0);

	const [mesesPlataforma, setMesesPlataforma] = useState(0);

	const [diasPlataforma, setDiasPlataforma] = useState(0);

	const [horasPlataforma, setHorasPlataforma] = useState(0);

	const [minutosPlataforma, setMinutosPlataforma] = useState(0);

	const [yearsPlataformaEmpleado, setYearsPlataformaEmpleado] = useState(0);

	const [mesesPlataformaEmpleado, setMesesPlataformaEmpleado] = useState(0);

	const [diasPlataformaEmpleado, setDiasPlataformaEmpleado] = useState(0);

	const [horasPlataformaEmpleado, setHorasPlataformaEmpleado] = useState(0);

	const [minutosPlataformaEmpleado, setMinutosPlataformaEmpleado] = useState(0);

	const [mostrarGraficaEmpleado, setMostrarGraficaEmpleado] = useState(false);

	const [mostrarGraficaInvitado, setMostrarGraficaInvitado] = useState(false);

	const [idPerfilEmpleado, setIdPerfilEmpleado] = useState(0);

	const [nombreEmpleadoSeleccionado, setNombreEmpleadoSeleccionado] = useState(null);

	const [ultimaLeccion, setUltimaLeccion] = useState(null);

	const [codigoCurso, setCodigoCurso] = useState(null);

	const [indexAvance, setIndexAvance] = useState(0);

	const joyrideRef = useRef(null);

	// const [tourActivo, setTourActivo] = useState(false);

	const [runTour, setRunTour] = useState(false);

	const [tourSteps, setTourSteps] = useState([
		{
			target: ".question-avances",
			content: "Bienvenido a mis avances, en esta sección puedes ver tu avances por curso de toda la plataforma, haz click en las barras de la gráfica para ver las lecciones completadas del curso.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".end-avances",
			content: "Si no tienes avance alguno, comienza viendo cualquier lección de la plataforma para comenzar a registrar tu avance en el sistema.",
			disableBeacon: true,
			placement: "left-start",
		},
	]);

	const {
		size: { width },
	} = useWindowResize();

	const handleStartTour = () => {
		setRunTour(!runTour);
	};

	const listarAvancePorCurso = () => {
		let arregloCursos = [];

		cursos.map((curso, idx) => {
			let cantidadLecciones = leccionesVistas.filter((dato) => dato.codigoCurso.startsWith(curso.codigoCurso));
			arregloCursos.push({
				name: curso.nombre,
				codigoCurso: curso.codigoCurso,
				lecciones: cantidadLecciones.length,
			});
		});

		setAvancePorCurso(arregloCursos);
	};

	const handleClick = async (data) => {
		try {
			//*Esta funcion se encargara de listar las lecciones del curso que se seleccione.
			const { codigoCurso, name, cursos } = data;

			setCodigoCurso(codigoCurso);

			const { data: datosLeccion } = await getLeccionesByCodigoCurso(codigoCurso);

			if (datosLeccion.length > 0) {
				setLeccionesSeleccionadas(datosLeccion);
				setMostrarLecciones(true);
			}

			const { data: calificacionCurso } = await getCalificacionPorCurso({
				idPerfil,
				idUsuarioEmpresarial,
				rol,
				codigoCurso,
			});
			// console.log(calificacionCurso);

			const infoCertificado = {
				idPerfil: idPerfil,
				codigoCurso,
			};

			const { data: puntaje } = await verificarCertificado(infoCertificado);

			// console.log( puntaje);
			setPuntajeCertificacion(puntaje.puntajeCertificacion);
		} catch (error) {
			console.log(error);
			setPuntajeCertificacion(0);
		}
	};

	const handleGraficaEmpleado = async (idPersona, idPerfil, nombreCompleto) => {
		try {
			// const { id, name } = target;

			const { data: tiempo } = await getTiempoPlataforma(idPersona);
			const { tiempoEnPlataforma } = tiempo;

			const duracion = moment.duration(tiempoEnPlataforma, "minutes");

			const { days, hours, minutes, months, years } = duracion._data;

			setDiasPlataformaEmpleado(days);
			setHorasPlataformaEmpleado(hours);
			setMinutosPlataformaEmpleado(minutes);
			setMesesPlataformaEmpleado(months);
			setYearsPlataformaEmpleado(years);

			setIdPerfilEmpleado(idPerfil);

			setIndexAvance(idPerfil);

			setNombreEmpleadoSeleccionado(nombreCompleto);

			setMostrarGraficaEmpleado(true);
			const { data } = await getLeccionesVistas(idPerfil);

			// console.log(data);
			if (Array.isArray(data)) {
				setLeccionesVistasEmpleado(data);
			} else {
				setLeccionesVistasEmpleado([data]);
			}
		} catch (error) {
			console.log(error);
			setLeccionesVistasEmpleado([]);
		}
	};

	const handleUltimaLeccion = (codigoLeccion) => {
		const codigoCurso = codigoLeccion.substring(0, 4);

		const codigoSeccionSeleccionada = codigoLeccion.substring(6, 0);

		// console.log("Ultima Leccion Vista: ", codigoLeccion);

		setContextValue({
			...contextValue,
			codigoCursoSeleccionado: codigoCurso,
			ultimoCursoTomado: codigoCurso,
			codigoSeccionSeleccionada,
		});

		handleChangeComponent(multiViewComponentsPaths.curso);
	};

	const obtenerFecha = (boolean, codigoLeccion) => {
		if (boolean) {
			let data = leccionesVistas.find((leccionVista) => leccionVista.codigoLeccion === codigoLeccion);
			return getFechaSinHora(data.fecha);
		} else {
			return " - ";
		}
	};

	useEffect(() => {
		const obtenerCursos = async () => {
			try {
				// const { data: cursos } = await getCursos();

				// setCursos(cursos);

				const { data: leccionesVistas } = await getLeccionesVistas(idPerfil);

				let arregloLeccionesVistas = [];

				let arregloCursos = [];
				let cantidadLecciones;

				cursos.map((curso, idx) => {
					if (!Array.isArray(leccionesVistas)) {
						arregloLeccionesVistas = [leccionesVistas];
						cantidadLecciones = arregloLeccionesVistas.filter((dato) => dato.codigoCurso.startsWith(curso.codigoCurso));
					} else {
						cantidadLecciones = leccionesVistas.filter((dato) => dato.codigoCurso.startsWith(curso.codigoCurso));
					}
					arregloCursos.push({
						name: curso.nombre,
						codigoCurso: curso.codigoCurso,
						lecciones: cantidadLecciones.length,
					});
				});

				setAvancePorCurso(arregloCursos);
				if (Array.isArray(leccionesVistas)) {
					setLeccionesVistas(leccionesVistas);
				} else {
					setLeccionesVistas([leccionesVistas]);
				}
				setLeccionesListadas(true);
			} catch (error) {
				console.log(error);
			}
		};

		obtenerCursos();

		const obtenerHistorial = async () => {
			try {
				try {
					const { data } = await getLeccionesVistas(idPerfil);
					if (Array.isArray(data)) {
						setLeccionesVistas(data);
					} else {
						setLeccionesVistas([data]);
					}
					setLeccionesListadas(true);
				} catch (error) {
					console.log(error);
				}
			} catch (error) {
				console.log(error);
			}
		};

		obtenerHistorial();

		if (rol === "asesor") {
			const obtenerUsuariosEmpresariales = async () => {
				try {
					const { data } = await getUsuariosByIdGrupo(idGrupo);
					if (Array.isArray(data)) {
						setEmpleados(data);
					} else {
						setEmpleados([data]);
					}
				} catch (error) {
					console.log(error);
				}
			};

			obtenerUsuariosEmpresariales();
		}

		const ultimaLeccion = async () => {
			try {
				const {
					data: { codigoLeccion },
				} = await getUltimaLeccionTomada(idPerfil);

				if (codigoLeccion.startsWith("ING")) {
					const { data: datos } = await getLeccionByCodigoIngles(codigoLeccion);

					setUltimaLeccionTomada(datos.nombre);
				} else {
					const { data: datos } = await getLeccionByCodigo(codigoLeccion);
					// console.log(datos);
					setUltimaLeccionTomada(datos.nombre);
				}
			} catch (error) {
				console.log(error);
			}
		};

		ultimaLeccion();

		const tiempoPlataforma = async () => {
			try {
				const { data } = await getTiempoPlataforma(idPersona);
				// console.log(data);
				const { tiempoEnPlataforma } = data;

				const duracion = moment.duration(tiempoEnPlataforma, "minutes");

				const { days, hours, minutes, months, years } = duracion._data;

				setDiasPlataforma(days);
				setHorasPlataforma(hours);
				setMinutosPlataforma(minutes);
				setMesesPlataforma(months);
				setYearsPlataforma(years);
			} catch (error) {
				console.log(error);
			}
		};

		tiempoPlataforma();

		const obtenerUltimaLeccionVista = async () => {
			try {
				const { data } = await getUltimaLeccionTomada(idPerfil);
				if (Array.isArray(data)) {
					setUltimaLeccion(data[0].codigoLeccion);
				} else {
					setUltimaLeccion(data.codigoLeccion);
				}
			} catch (error) {
				console.log(error);
			}
		};

		obtenerUltimaLeccionVista();
	}, []);

	useEffect(() => {
		listarAvancePorCurso();

		const obtenerHistorial = async () => {
			try {
				const { data } = await getLeccionesVistas(idPerfil);
				if (Array.isArray(data)) {
					setLeccionesVistas(data);
				} else {
					setLeccionesVistas([data]);
				}
				setLeccionesListadas(true);
			} catch (error) {
				console.log(error);
			}
		};

		obtenerHistorial();

		setDataTableColumns([
			...columns,
			{
				name: "Fecha en que se completó",
				cell: (row) => (
					<div className="text-center">
						<p>
							{" "}
							{obtenerFecha(
								leccionesVistas.some((leccionVista) => leccionVista.codigoLeccion === (leccionVista.codigoCurso?.startsWith("ING") ? row.claseLeccion : row.codigoLeccion)) ? true : false,
								codigoCurso?.startsWith("ING") ? row.claseLeccion : row.codigoLeccion
							)}{" "}
						</p>
					</div>
				),
				center: true,
				ignoreRowClick: true,
				allowOverflow: false,
				width: "25%",
			},
			{
				name: "Completado",
				cell: (row) => (
					<div className="text-center">
						<Checkbox
							className="mx-3"
							checked={leccionesVistas.some((leccionVista) => leccionVista.codigoLeccion === (leccionVista.codigoLeccion?.startsWith("ING") ? row.claseLeccion : row.codigoLeccion))}></Checkbox>
					</div>
				),
				center: true,
				ignoreRowClick: true,
				allowOverflow: false,
				width: "25%",
			},
		]);

		// //*Se asigna la tabla para ver los avances de cada empleado (Grafica)
		// setDataTableColumnsEmpleados([
		// 	...columnsEmpleados,
		// 	{
		// 		name: "Avance individual",
		// 		cell: (row) => (
		// 			<>
		// 				{/* //*Se hizo un componente reciclable, modificar el cambio de state para mostrar el componente */}
		// 				<Button className="m-2" id={row.idPerfil} onClick={handleGraficaEmpleado}>
		// 					<FontAwesomeIcon icon={faEye} /> Ver avance
		// 				</Button>

		// 			</>
		// 		),
		// 		center: true,
		// 		ignoreRowClick: true,
		// 		allowOverflow: true,
		// 		width: "25%",
		// 	},
		// ]);
	}, [leccionesSeleccionadas]);

	useEffect(() => {
		listarAvancePorCurso();

		const obtenerHistorial = async () => {
			try {
				const { data } = await getLeccionesVistas(idPerfilEmpleado);
				if (Array.isArray(data)) {
					setLeccionesVistasEmpleado(data);
				} else {
					setLeccionesVistasEmpleado([data]);
				}
				setLeccionesListadasEmpleado(true);
			} catch (error) {
				console.log(error);
			}
		};

		obtenerHistorial();

		// setDataTableColumns([
		// 	...columns,
		// 	{
		// 		name: "Completado",
		// 		cell: (row) => (
		// 			<>
		// 				<Checkbox className="mx-3" checked={leccionesVistasEmpleado.some((leccionVista) => leccionVista.codigoLeccion === row.codigoLeccion)}>
		// 				</Checkbox>
		// 			</>
		// 		),
		// 		ignoreRowClick: true,
		// 		allowOverflow: true,
		// 		width: "25%",
		// 	},
		// ]);

		//*Se asigna la tabla para ver los avances de cada empleado (Grafica)
		setDataTableColumnsEmpleados([
			...columnsEmpleados,
			{
				name: "Avance individual",
				cell: (row, idx) => (
					<>
						<Button
							className="m-2"
							id={row.idPerfil}
							name={row.nombreCompleto + " " + row.apellidos}
							onClick={() => handleGraficaEmpleado(row.idPersona, row.idPerfil, row.nombreCompleto + " " + row.apellidos)}>
							Ver avance
						</Button>
					</>
				),
				center: true,
				ignoreRowClick: true,
				allowOverflow: true,
				width: "25%",
			},
		]);
	}, [leccionesSeleccionadasEmpleado]);

	return (
		<>
			<Container fluid>
				<Joyride
					locale={spanishOptionsJoyRide}
					ref={joyrideRef}
					steps={tourSteps}
					run={runTour}
					continuous={true}
					showSkipButton
					hideCloseButton
					disableOverlayClose={true}
					disableCloseOnEsc={false}
					disableScrolling={false}
					scrollToFirstStep={true}
					scrollOffset={100}
					callback={(data) => {
						if (data.status === "finished") {
							setRunTour(false);
						} else if (data.action === "skip") {
							setRunTour(false);
						}
					}}
					styles={{
						options: {
							primaryColor: "#ff9900",
							textColor: "#00152A",
							width: 900,
							zIndex: 1000,
						},
					}}
				/>

				<Row className="mb-4 justify-content-between">
					<Col className="col-auto">
						<DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
					</Col>
					{/* //TODO Al colocar el icono de ayuda, validar si hay algún avance y desplegarlo si si lo hay */}

					{width > 768 && (
						<Col className="col-auto question-avances end-avances d-flex align-items-center">
							<OverlayTrigger placement={"top"} overlay={<Tooltip>Conoce tu entorno</Tooltip>}>
								<span>
									<FontAwesomeIcon className="mx-5 pointer " onClick={handleStartTour} style={{ fontSize: "2rem" }} icon={faQuestion} />
								</span>
							</OverlayTrigger>
						</Col>
					)}
				</Row>

				<Row>
					<Col>
						<TitleDashboard title={"Mis avances"} />
					</Col>
				</Row>

				<Row className="mt-4">
					<Col className="col-12 mb-4">
						<div className="p-3 shadow info-cuenta-avances">
							<h3>Información de la cuenta</h3>
							<p>
								<b>Ultimo ingreso a la plataforma: </b>
								{fechaUltimoAcceso !== null ? getDateFromUnix(fechaUltimoAcceso) : "Hubo un error al recuperar la fecha de ultimo acceso"}
							</p>
							<p>
								<b>Ultima lección tomada: </b>{" "}
								{ultimaLeccionTomada !== null ? (
									<a className="pointer" onClick={() => handleUltimaLeccion(ultimaLeccion)}>
										{ultimaLeccionTomada}
									</a>
								) : (
									"No has visto ninguna lección"
								)}
							</p>
							<p>
								Tiempo en plataforma: {yearsPlataforma > 0 && `${yearsPlataforma} ${yearsPlataforma === 1 ? "Año" : "Años"}`}{" "}
								{mesesPlataforma > 0 && `${mesesPlataforma} ${mesesPlataforma === 1 ? "Mes" : "Meses"}`} {diasPlataforma > 0 && `${diasPlataforma} ${diasPlataforma === 1 ? "Día" : "Días"}`}{" "}
								{horasPlataforma > 0 && `${horasPlataforma} ${horasPlataforma === 1 ? "Hora" : "Horas"}`}{" "}
								{minutosPlataforma > 0 && `${minutosPlataforma} ${minutosPlataforma === 1 ? "Minuto" : "Minutos"}`}
							</p>
						</div>
					</Col>
					<Col className="col-12">
						<Tabs className="test" id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
							<Tab eventKey="avance-individual" title="Avance individual">
								{/* //*AVANCES INDIVIDUALES */}
								{mostrarLecciones ? (
									<Row className="mt-4 mb-5">
										<Col className="col-12 mb-3">
											<Row className="justify-content-end">
												<Col>
													<h6 style={{ color: "grey" }}>
														<FontAwesomeIcon icon={faList} /> Avance por lecciones
													</h6>
												</Col>
												<Col className="col-auto pointer" onClick={() => setMostrarLecciones(false)}>
													<FontAwesomeIcon icon={faArrowLeft} /> Regresar
												</Col>
											</Row>
										</Col>
										{/* //*USAR SOLO LA CALIFICACION APROBATORIA DEL CURSO SI NO NO MOSTRARLA */}
										{puntajeCertificacion >= 85 && (
											<Col className="col-12">
												<p>
													<FontAwesomeIcon icon={faCheckCircle} color={"green"} /> Calificación final del curso: {puntajeCertificacion}%
												</p>
											</Col>
										)}
										<Col>
											{/* //*Aqui se listaran las lecciones del curso que se haya seleccionado */}
											<DataTablesRecipient columns={dataTableColumns} data={leccionesSeleccionadas} />
										</Col>
									</Row>
								) : (
									<Row className="mt-4 mb-4 justify-content-center">
										<Col className="mb-4">
											<h6>
												<FontAwesomeIcon icon={faChartBar} /> Avance por curso
											</h6>
										</Col>

										<Col className="col-12">
											{leccionesVistas.length > 0 ? (
												<ResponsiveContainer width="100%" height={400}>
													<BarChart data={avancePorCurso}>
														<XAxis dataKey="name" angle={-45} tick={false} interval={0} />
														<YAxis />
														<CartesianGrid strokeDasharray="3 3" />
														<Tooltip
															itemStyle={{
																color: modoOscuro ? "#000" : "#fff",
															}}
															labelStyle={{
																color: modoOscuro ? "#000" : "#fff",
															}}
														/>
														<Legend
															wrapperStyle={{
																color: modoOscuro ? "#000" : "#fff",
															}}
														/>
														<Bar className="pointer" onClick={handleClick} dataKey="lecciones" fill="#F59E0B" />
													</BarChart>
												</ResponsiveContainer>
											) : (
												<>
													<h6 className="mb-5">Por el momento no tienes ningun avance, ¿Qué esperas a comenzar...?</h6>
													<ListadoCursos handleChangeComponent={handleChangeComponent} />
												</>
											)}
										</Col>
									</Row>
								)}
							</Tab>
							{rol === "asesor" && (
								<Tab eventKey="avance-grupal" title="Avance grupal">
									{!mostrarGraficaEmpleado ? (
										<Row className="mb-5">
											<Col className="col-12">
												<Row>
													<Col className="mb-4 mt-4">
														<h6>
															<FontAwesomeIcon icon={faList} /> Usuarios empresariales
														</h6>
													</Col>
												</Row>
											</Col>

											<Col>
												{/* //*Aqui se listaran los usuarios empresariales para ver sus avances por empleado */}
												<DataTablesRecipient columns={dataTableColumnsEmpleados} data={empleados} />
											</Col>
										</Row>
									) : (
										<ListarAvanceEmpleado
											nombreEmpleadoSeleccionado={nombreEmpleadoSeleccionado}
											indexAvance={indexAvance}
											idPerfilEmpleado={idPerfilEmpleado}
											setMostrarGraficaEmpleado={setMostrarGraficaEmpleado}
											diasPlataformaEmpleado={diasPlataformaEmpleado}
											minutosPlataformaEmpleado={minutosPlataformaEmpleado}
											horasPlataformaEmpleado={horasPlataformaEmpleado}
											mesesPlataformaEmpleado={mesesPlataformaEmpleado}
											yearsPlataformaEmpleado={yearsPlataformaEmpleado}
											setMostrarGraficaInvitado={setMostrarGraficaInvitado}
										/>
									)}
								</Tab>
							)}
						</Tabs>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default MisAvances;
