import "@assets/css/components/SideBarCollapse/style.css";

import { Menu, MenuItem, Sidebar, SubMenu, menuClasses } from "react-pro-sidebar";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import SidebarItems from "@components/general/SideBarCollapse/SidebarItems/SidebarItems";
import UserContext from "@context/User/UserContext";
import { getCategoriasCursos } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import useWindowResize from "@customHooks/useWindowResize";
import { getCursosLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";
import typesCodigosNotificacionUsuario from "@helpers/types/typesCodigosNotificacionUsuario";

const SiderBarCollapse = ({ isSidebarActive, setIsSidebarActive, activeView, handleChangeComponent, rtl }) => {
	const prefixCategorias = "categoria_";

	const [defaultActiveItem] = SidebarItems;

	const { contextValue: userContextValue, setContextValue: setUserContextValue } = useContext(UserContext);

	const { idCredencial } = userContextValue.usuarioLogeado;

	const { usuarioLogeado, notificacionesUsuario = [] } = userContextValue;

	let { idGrupo, rol, servicioActivo, estatusCredencial } = usuarioLogeado;

	const [activeItem, setActiveItem] = useState(activeView ? SidebarItems.find((item) => item.path === activeView) : defaultActiveItem);

	const [showCollapseListadoCursos, setShowCollapseListadoCursos] = useState(false);

	const [cursoSeleccionado, setCursoSeleccionado] = useState(false);

	const [notificacionesDisponiblesForoAyuda, setNotificacionesDisponiblesForoAyuda] = useState(
		notificacionesUsuario.find((noti) => noti.codigoNotificacion === typesCodigosNotificacionUsuario.nuevoPostForoAyuda && noti.leida === 0) !== undefined
	);

	const [categorias, setCategorias] = useState([]);

	const [cursos, setCursos] = useState([]);

	const [cursosLicencia, setCursosLicencia] = useState([]);

	const [cursosCategoria, setCursosCategoria] = useState([]);

	const [menuRootStyles, setMenuRootStyles] = useState({});

	const cursosFiltrados = cursos.filter((curso) => cursosLicencia.find((cursoLicencia) => cursoLicencia.codigoCurso === curso.codigoCurso));

	const categoriasFiltradas = categorias.filter((categoria) => cursosFiltrados.some((curso) => curso.idCategoria === categoria.idCategoria));

	useWindowResize();

	const handleItemClick = (item) => {
		setActiveItem(item);

		if (item.path === multiViewComponentsPaths.misCursos) {
			if (!isSidebarActive) {
				setIsSidebarActive(!isSidebarActive);
			}

			setShowCollapseListadoCursos(!showCollapseListadoCursos);
		} else {
			handleChangeComponent(item.path);

			setCursoSeleccionado(false);
		}
	};

	const handleSeleccionSubMenuCursos = async ({ currentTarget }) => {
		try {
			const idCategoria = parseInt(currentTarget.id.split("-")[2]);

			setCursosCategoria({
				...cursosCategoria,
				[`${prefixCategorias}${idCategoria}`]: cursosFiltrados.filter((curso) => curso.idCategoria === idCategoria),
			});
		} catch (error) {
			console.log("Error al consultar el curso seleccionado: ", error);
		}
	};

	useEffect(() => {
		if (!isSidebarActive) {
			setShowCollapseListadoCursos(false);
		}

		setMenuRootStyles({
			[`.${menuClasses.subMenuContent}`]: {
				// ...isSidebarActive && { width: '230px'}
			},
		});
	}, [isSidebarActive]);

	useEffect(() => {
		const consultarCategorias = async () => {
			try {
				const { data: categorias } = await getCategoriasCursos();

				setCategorias(categorias);
			} catch (error) {
				console.log("Error al consultar las categorías: ", error);
			}
		};

		const consultarCursos = async () => {
			try {
				const { data: cursos } = await getCursos();

				setCursos(cursos);
			} catch (error) {
				console.log("Error al consultar los cursos: ", error);
			}
		};

		const consultarCursosLicencia = async () => {
			try {
				const {
					data: { cursos },
				} = await getCursosLicencia(idCredencial);

				setCursosLicencia(JSON.parse(cursos));
			} catch (error) {
				console.log("Error al consultar los cursos de la licencia: ", error);
			}
		};

		consultarCategorias();

		consultarCursos();

		consultarCursosLicencia();
	}, []);

	const handleCursoSelection = (codigoCurso) => {
		setCursoSeleccionado(true);

		setUserContextValue({
			...userContextValue,
			codigoCursoSeleccionado: codigoCurso,
			ultimaLeccionTomada: "",
			codigoSeccionSeleccionada: codigoCurso + "01",
		});

		handleChangeComponent(multiViewComponentsPaths.curso);

		document.getElementById("sidebar-item-cursos").click();
	};

	const getTooltipTitle = (item) => (item.path === multiViewComponentsPaths.foroAyuda ? item.title(notificacionesDisponiblesForoAyuda) : item.title);

	const getSidebarIcon = (item) => (item.path === multiViewComponentsPaths.foroAyuda ? item.icon(notificacionesDisponiblesForoAyuda) : item.icon);

	return (
		<Sidebar style={{ zIndex: "1000" }} collapsed={!isSidebarActive} breakPoint="md" backgroundColor="#00152A" className="sidebar-scroll sidebar">
			<div className="pb-5">
				<Menu
					menuItemStyles={{
						button: ({ level, active, disabled }) => {
							if (level === 0) {
								return {
									backgroundColor: cursoSeleccionado ? "#00152A" : active ? "#FF9900" : undefined,
									color: cursoSeleccionado ? "#FFF" : active ? "#000" : "#FFF",
								};
							}
						},
					}}
					rootStyles={menuRootStyles}>
					{SidebarItems.map((item, idxSidebarItem) => {
						// Si el rol no está permitido, no se renderiza el item
						if (!item.permittedTo.includes(rol)) return null;

						if (idGrupo !== null && idGrupo !== undefined) rol = "empleado";

						return (
							<div key={idxSidebarItem} id={item.id}>
								{item.path === multiViewComponentsPaths.misCursos ? (
									estatusCredencial ? 
										<OverlayTrigger key={idxSidebarItem} placement="right" delay={{ show: 250, hide: 400 }} overlay={<Popover id={`popover`}>
											<Popover.Header as="h3">{item.title}</Popover.Header>
											<Popover.Body>
												{item.descripcion}
											</Popover.Body>
										</Popover>}>
											<SubMenu
												rootStyles={{
													[`.${menuClasses.button}`]: {
														backgroundColor: cursoSeleccionado ? "#FF9900!important" : undefined,
														color: cursoSeleccionado ? "#000" : "#FFF",
														"&:hover": {
															backgroundColor: "#FF9900!important",
															color: "#000",
														},
													},
												}}
												label={item.title}
												icon={item.icon}>
												{categoriasFiltradas.map((categoria, idxCategoria) => (
													<SubMenu
														key={idxCategoria}
														rootStyles={{
															[`.${menuClasses.button}`]: {
																backgroundColor: "#FF9900!important",
																color: "#000",
																"&:hover": {
																	backgroundColor: "#FF9900!important",
																	color: "#000",
																},
															},
														}}
														id={`categoria-curso-${categoria.idCategoria}`}
														label={categoria.nombre}
														onClick={handleSeleccionSubMenuCursos}>
														{cursosCategoria[`${prefixCategorias}${categoria.idCategoria}`]?.map((curso, idxCursoCategoria) => (
															<MenuItem
																onClick={() => {
																	handleCursoSelection(curso.codigoCurso);
																}}
																key={idxCursoCategoria}
																id={`curso-${curso.codigoCurso}`}>
																{curso.nombre}
															</MenuItem>
														))}
													</SubMenu>
												))}
											</SubMenu>
										</OverlayTrigger>
									: null
								) : item.path === multiViewComponentsPaths.misPagos ? (
									<OverlayTrigger key={idxSidebarItem} placement="right" delay={{ show: 250, hide: 400 }} overlay={<Popover id={`popover`}>
									<Popover.Header as="h3">{getTooltipTitle(item)}</Popover.Header>
									<Popover.Body>
										{item.descripcion}
									</Popover.Body>
								</Popover>}>
										<MenuItem
											id={item.id}
											active={activeItem === item}
											className={item.classNames}
											icon={getSidebarIcon(item)}
											key={idxSidebarItem}
											onClick={() => handleItemClick(item)}
											rootStyles={{
												[`.${menuClasses.button}`]: {
													"&:hover": {
														backgroundColor: "#FF9900!important",
														color: "#000!important",
													},
												},
											}}>
											{getTooltipTitle(item)}
										</MenuItem>
									</OverlayTrigger>
								) : servicioActivo ? (
									// Renderización
									<OverlayTrigger key={idxSidebarItem} placement="right" delay={{ show: 250, hide: 400 }} overlay={<Popover id={`popover`}>
									<Popover.Header as="h3">{getTooltipTitle(item)}</Popover.Header>
									<Popover.Body>
										{item.descripcion}
									</Popover.Body>
								</Popover>}>
										<MenuItem
											id={item.id}
											active={activeItem === item}
											className={item.classNames}
											icon={getSidebarIcon(item)}
											key={idxSidebarItem}
											onClick={() => handleItemClick(item)}
											rootStyles={{
												[`.${menuClasses.button}`]: {
													"&:hover": {
														backgroundColor: "#FF9900!important",
														color: "#000!important",
													},
												},
											}}>
											{getTooltipTitle(item)}
										</MenuItem>
									</OverlayTrigger>
								) : null}
							</div>
						);
					})}
				</Menu>
			</div>
		</Sidebar>
	);
};

export default SiderBarCollapse;
