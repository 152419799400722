import { Redirect, Route, Switch } from "react-router-dom";

import Dashboard from "@views/Dashboard/Dashboard";
import FormularioRegistroEmpleado from "@components/general/Login/RegistroEmpleado/FormularioRegistroEmpleado/FormularioRegistroEmpleado";
import Intereses from "@components/general/Intereses/Intereses";

import Login from "@views/Login/Login";
import React, { useContext } from "react";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";

import { RecuperacionCredenciales } from "@components/general/Login/RecuperacionCredenciales/RecuperacionCredenciales";
import SeleccionPerfiles from "@components/usuario/SeleccionPerfiles/SeleccionPerfiles";

import ListadoCursos from "@components/general/Cursos/ListadoCursos";
import Certificado from "@components/general/certificados/Certificado";
import UserContext from "@context/User/UserContext";
import FormularioRegistroInicial from "@views/FormularioRegistroInicial/FormularioRegistroInicial";
import AbonoRealizadoLoggedIn from "@components/usuario/MisPagos/components/abono/AbonoRealizadoLoggedIn";
import LiquidacionRealizadaLoggedIn from "@components/usuario/MisPagos/components/liquidacion/LiquidacionRealizadaLoggedIn";
import PrivateRoute from "./PrivateRoute";
import CorsFrame from "@views/corsFrame";
import CompraCertificacionRealizada from "@components/general/CursoPropedeutico/CompraCertificacionRealizada/CompraCertificacionRealizada";
import CompraCertificacionRealizaPublica from "@views/CompraCertificacionMos/CompraCertificacionRealizada";
import CompraLicencia from "@views/CompraLicencia/CompraLicencia";
import CompraRealizada from "@views/CompraLicencia/CompraRealizada";
import FormularioRegistroSAI from "@views/FormularioRegistroSAI/FormularioRegistroSAI";
import CompraFinalizada from "@views/CompraLicencia/CompraFinalizada";
import RenovacionLicencia from "@views/RenovacionLicencia/RenovacionLicencia";
import CompraLinkPago from "@views/CompraLicencia/CompraLinkPago";
import CompraLinkPagoFinalizada from "@views/CompraLicencia/CompraLinkPagoFinalizada";
import ExamenDiagnosticoLecturaVeloz from "@views/LecturaVeloz/ExamenDiagnosticoLecturaVeloz";
import CompraLecturaVelozRealizada from "@views/CompraLicencia/CompraLecturaVelozRealizada";
import AbonoLicencia from "@views/AbonoLicencia/AbonoLicencia";
import AbonoRealizado from "@views/AbonoLicencia/AbonoRealizado";
import LiquidacionLicencia from "@views/LiquidacionLicencia/LiquidacionLicencia";
import LiquidacionRealizada from "@views/LiquidacionLicencia/LiquidacionRealizada";
import FormularioRecuperacionContraseña from "@components/general/Login/RecuperacionCredenciales/FormularioRecuperacionContraseña";
import CompraCertificacion from "@views/CompraCertificacionMos/CompraCertificacion";
import RecuperacionNombreUsuario from "@components/general/Login/RecuperacionNombreUsuario/RecuperacionNombreUsuario";
import FormularioRecuperacionNombreUsuario from "@components/general/Login/RecuperacionNombreUsuario/FormularioRecuperacionNombreUsuario";

// SON LAS VISTAS PÚBLICAS QUE ESTÁN FUERA DEL DASHBOARD PRINCIPAL

const DashboardRoutes = () => {
	const { contextValue, setContextValue } = useContext(UserContext);

	return (
		<Switch>
			{/* Formulario de Registro inicial  */}
			<Route exact sensitive={true} path={dashboardRoutesPaths.preRegistro} component={FormularioRegistroInicial} />

			{/* Dashboard Principal */}
			<PrivateRoute exact sensitive={true} path={dashboardRoutesPaths.dashboard} component={Dashboard} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.listadoCursos} component={ListadoCursos} />

			{/* Inicio de sesión */}
			<Route exact sensitive={true} path={dashboardRoutesPaths.login} component={Login} />

			{/* Registro de Empleados */}
			<Route exact sensitive={true} path={dashboardRoutesPaths.registroEmpleado} component={FormularioRegistroEmpleado} />

			{/* Recuperación de credenciales */}
			<Route exact sensitive={true} path={dashboardRoutesPaths.recuperacionCredenciales} component={RecuperacionCredenciales} />

			{/* Recuperación de credenciales */}
			<Route exact sensitive={true} path={dashboardRoutesPaths.recuperacionNombreUsuario} component={RecuperacionNombreUsuario} />

			{/* Recolección de intereses */}
			<Route exact sensitive={true} path={dashboardRoutesPaths.intereses} component={Intereses} />

			{/* Selección de perfiles */}
			<Route exact sensitive={true} path={dashboardRoutesPaths.perfiles} component={SeleccionPerfiles} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.certificado} component={Certificado} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.formularioRecuperacionContrasenia} component={FormularioRecuperacionContraseña} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.formularioRecuperacionNombreUsuario} component={FormularioRecuperacionNombreUsuario} />

			{/* <Route exact sensitive={true} path='/home-inicial' component={ UsuarioLogueadoHome } /> */}

			{/* Lectura Veloz */}
			<Route exact sensitive={true} path={dashboardRoutesPaths.examenDiagnosticoLecturaVeloz} component={ExamenDiagnosticoLecturaVeloz} />


			{/* STRIPE */}
			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.compra.compraLink} component={CompraLinkPago} />
			
			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.compra.compraLinkFinalizada} component={CompraLinkPagoFinalizada} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.compra.compraLicencia} component={CompraLicencia} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.compra.compraRealizada} component={CompraRealizada} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.compra.compraFinalizada} component={CompraFinalizada} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.abonoLicencia} component={AbonoLicencia} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.abonoRealizado} component={AbonoRealizado} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.abonoRealizadoLoggedIn} component={AbonoRealizadoLoggedIn} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.liquidacionLicencia} component={LiquidacionLicencia} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.liquidacionRealizada} component={LiquidacionRealizada} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.liquidacionRealizadaLoggedIn} component={LiquidacionRealizadaLoggedIn} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.compra.compraCertificacionMos} component={CompraCertificacion} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.compra.compraCertificacionMosRealizadaPublica} component={CompraCertificacionRealizaPublica} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.compra.compraCertificacionMosRealizada} component={CompraCertificacionRealizada} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.renovacion.renovacionLicencia} component={RenovacionLicencia} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.formularioRegistroSai} component={FormularioRegistroSAI} />

			<Route exact sensitive={true} path={dashboardRoutesPaths.pagos.compra.compraLecturaVelozRealizada} component={CompraLecturaVelozRealizada} />

			{/* <Route exact sensitive={true} path='/testing-aws' component={ TestingAws } /> */}
			<Route exact sensitive={true} path="/test-cors" component={CorsFrame} />
			{/* Ruta por defecto  */}
			{Object.keys(contextValue).length === 0 && <Redirect to={dashboardRoutesPaths.default} />}
		</Switch>
	);
};

export default DashboardRoutes;
