import "@assets/css/views/FormularioRegistroInicial/style.css";

import { Card, Col, Container, Form, Image, InputGroup, Modal, Navbar, Row } from "react-bootstrap";
import React, { useEffect } from "react";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { confirmarRegistroInicial, getDatosFolio, validateTokenFolio } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import validarRestriccionesContrasenia from "@helpers/validaciones/validarRestriccionesContrasenia/validarRestriccionesContrasenia";
import Loader from "@helpers/loader/loader";
import {
	registrarTokenRegistro,
	validateExistenciaAlias,
	validateExistenciaCURP,
	validateExistenciaCorreo,
	validateExistenciaFolioContrato,
	validateExistenciaNombreUsuario,
} from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { ModalSweetAlert, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import Particles from "react-tsparticles";
import { useCallback } from "react";

import { loadFull } from "tsparticles";
import { addUsuarioLecturaVeloz, addUsuarioTitular } from "@helpers/httpRequests/httpRequestsUsuarios/registro/httpRequestsRegistroUsuarios";
import ErrorValidacionDatosRegistroInicial from "./ErrorValidacionDatosRegistroInicial";
import { Animated } from "react-animated-css";
import { useRef } from "react";
import useCapsLockWatcher from "@customHooks/useCapsLockWatcher";
import CapsLockTooltip from "@components/general/CapsLockTooltip/CapsLockTooltip";
import { getLicencia } from "@helpers/httpRequests/httpRequestsLicencias/httpRequestsLicencias";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import AvisoPrivacidad from "./AvisoPrivacidad";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import { NotificationManager } from "react-notifications";
import { Checkbox, CssBaseline, FormControlLabel, FormGroup, ThemeProvider, createTheme } from "@mui/material";

const particlesOptions = {
	fpsLimit: 60,
	particles: {
		number: {
			value: 100,
			density: {
				enable: true,
			},
		},
		color: {
			value: ["#ffcc00", "#757676", "#ff9900", "#00152A", "#f07f13"],
		},
		opacity: {
			value: { min: 0.1, max: 0.5 },
		},
		size: {
			value: { min: 1, max: 3 },
		},
		move: {
			enable: true,
			speed: 6,
			random: false,
		},
	},
	interactivity: {
		detectsOn: "window",
		events: {
			onClick: {
				enable: true,
				mode: "push",
			},
			resize: true,
		},
	},
	background: {
		// image: "radial-gradient(#4a0000, #000)",
		image: "radial-gradient(#01488f, rgba(0, 21, 42, 1))",
	},
};

const urlsManuales = {
	estudiante: "https://globalclickmexico.com/item/Manual%20de%20usuario%20Click+.pdf",
	asesor: "https://globalclickmexico.com/item/Manual%20para%20asesor%20Click+.pdf",
};

const FormularioRegistroInicial = ({ location, history }) => {
	const urlParams = new URLSearchParams(useLocation().search);

	const inputContrasenia = useRef(null);

	const isCapsLockOn = useCapsLockWatcher(inputContrasenia);

	const [formValues, setFormValues] = useState({
		folioContrato: "",
		nombreUsuario: "",
		alias: "",
		contrasenia: "",
		confirmacionContrasenia: "",
		nombre: "",
		nombreCompleto: "",
		apellidos: "",
		correo: "",
		curp: "",
		idLicencia: 0,
		cantidadEstudiantes: null,
	});

	const [validacion, setValidacion] = useState({
		nombreUsuario: false,
		alias: false,
		contrasenia: false,
		confirmacionContrasenia: false,
		curp: false,
		caracteresContrasenia: false,
		mayusculaContrasenia: false,
		numeroContrasenia: false,
		caracteresEspecialesNombreUsuario: true,
		espaciosNombreUsuario: true,
	});

	const handleOnChangePasswords = async ({ target }) => {
		try {
			const { name, value } = target;

			if (name === "contrasenia") {
				setFormValues({
					...formValues,
					contrasenia: value,
				});

				const validacionesContrasenia = await validarRestriccionesContrasenia({
					contrasenia: value,
				});

				setValidacion({
					...validacion,
					...validacionesContrasenia,
				});
			} else if (name === "confirmacionContrasenia") {
				setFormValues({
					...formValues,
					confirmacionContrasenia: value,
				});

				let contraseniasCoincidentes = false;

				if (value === formValues.contrasenia) {
					contraseniasCoincidentes = true;
				}

				setValidacion({
					...validacion,
					confirmacionContrasenia: contraseniasCoincidentes,
				});
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	const [showPasswords, setShowPasswords] = useState(false);

	const [showLoader, setShowLoader] = useState(true);

	const [loading, setLoading] = useState(false);

	const [activarCorreoAlternativo, setActivarCorreoAlternativo] = useState(false);

	const [error, setError] = useState(false);

	const [errorReason, setErrorReason] = useState("");

	const [datosLicencia, setDatosLicencia] = useState(null);

	const [lgShow, setLgShow] = useState(false);

	const handleShowPasswords = () => {
		setShowPasswords(!showPasswords);
	};

	const handleOnChange = async ({ target }) => {
		const { name, value } = target;

		setFormValues({
			...formValues,
			[name]: name === "curp" ? value.toUpperCase() : value,
		});

		if (value === "") {
			return setValidacion({
				...validacion,
				[name]: false,
			});
		}

		switch (name) {
			case "nombreUsuario":
				let nombreUsuarioValido = false;

				let espacios = true;
				let caracteresEspeciales = true;

				// console.log('espacios: ', value.includes(" "));

				// if (value.includes(" ")) {
				// 	espacios = false;
				// }

				if (regExpStrings.regexCaracteresEspecialesNoArroba(value)) {
					caracteresEspeciales = false;
				}

				try {
					await validateExistenciaNombreUsuario(value);

					nombreUsuarioValido = true;
				} catch (error) {
					console.log("Error validando el nombre de usuario: ", error);

					nombreUsuarioValido = false;
				}

				setValidacion({
					...validacion,
					nombreUsuario: nombreUsuarioValido,
					espaciosNombreUsuario: espacios,
					caracteresEspecialesNombreUsuario: caracteresEspeciales,
				});
				break;

			case "alias":
				let aliasValido = false;

				try {
					await validateExistenciaAlias(value);

					aliasValido = true;
				} catch (error) {
					console.log("Error validando el alias: ", error);

					aliasValido = false;
				}

				setValidacion({
					...validacion,
					alias: aliasValido,
				});

				break;

			case "curp":
				let curpValido = false;

				try {
					await validateExistenciaCURP(value);

					curpValido = true;
				} catch (error) {
					console.log("Error validando el CURP: ", error);

					curpValido = false;
				}

				setValidacion({
					...validacion,
					curp: curpValido,
				});
				break;

			case "correo":
				let correoValido = false;

				try {
					await validateExistenciaCorreo(value);

					correoValido = true;
				} catch (error) {
					console.log("Error validando correo: ", error);

					correoValido = false;
				}

				setValidacion({
					...validacion,
					correo: correoValido,
				});
				break;

			default:
				break;
		}
	};

	const handleOnSubmit = async (e) => {
		e.preventDefault();

		const { curp, alias, nombreUsuario, confirmacionContrasenia } = formValues;

		if (curp === "") {
			return NotificationManager.error("Ingresa tu CURP antes de continuar");
		}

		if (alias === "") {
			return NotificationManager.error("Ingresa tu alias de usuario antes de continuar");
		}

		if (nombreUsuario === "") {
			return NotificationManager.error("Ingresa tu nombre de usuario antes de continuar");
		} else if (!validacion.nombreUsuario) {
			return NotificationManager.error("Ingresa un nombre de usuario válido");
		}

		if (confirmacionContrasenia === "") {
			return NotificationManager.error("Ingresa tu contraseña antes de continuar");
		}

		try {
			setLoading(true);

			if (datosLicencia.idLicencia === 4 || datosLicencia.nombre === "Lectura Inteligente") {
				await addUsuarioLecturaVeloz(formValues);
			} else {
				await addUsuarioTitular(formValues);
			}

			await confirmarRegistroInicial({
				folioContrato: urlParams.get("folioContrato"),
				tokenRegistro: urlParams.get("token"),
				nombreUsuario: formValues.nombreUsuario,
				contrasenia: formValues.contrasenia,
				curp: formValues.curp,
			});

			await registrarTokenRegistro({
				folioContrato: urlParams.get("folioContrato"),
				tokenRegistro: urlParams.get("token"),
			});

			setLoading(false);

			await ModalSweetAlert({
				icon: "success",
				title: "¡Registro completado con éxito!",
				text: "Un correo fue enviado a tu dirección de correo con tus datos de acceso.",
				confirmButtonText: "Entendido",
				confirmButtonColor: "#FFCC00",
			});

			history.replace(dashboardRoutesPaths.login);
		} catch (error) {
			console.log("Ocurrió un error al registrar el usuario: ", error);

			if (error.code) {
				NotificacionExtendida("error", error.message);
			}
		}
	};

	const showManualUsuario = () => {
		setLgShow(true);
		// const { licenciaEmpresarial } = datosLicencia;
		// const urlManual = `//docs.google.com/gview?url=${licenciaEmpresarial ? urlsManuales.asesor : urlsManuales.estudiante}&embedded=true`;
		// console.log(urlManual);
		// ModalSweetAlert({
		// 	title: "Manual de Usuario",
		// 	html: (
		// 		<iframe
		// 			id="iframePDF"
		// 			width="100%"
		// 			height="100%"
		// 			// style="border: none;"
		// 			allow="autoplay; encrypted-media;fullscreen"
		// 			loading="lazy"
		// 			title="Lección actual"
		// 			className="embed-responsive-item"
		// 			// src={urlManual}
		// 			src={licenciaEmpresarial ? urlsManuales.asesor : urlsManuales.estudiante}></iframe>
		// 	),
		// });
	};

	useEffect(() => {
		const folioContrato = urlParams.get("folioContrato");

		const token = urlParams.get("token");

		const consultarDatosCliente = async (folioContratoCliente) => {
			try {
				const { cliente: datosContrato, cantidadEstudiantes, idLicencia } = await getDatosFolio(folioContratoCliente);

				const { nombre, apellidoPaterno, apellidoMaterno, email: correo } = datosContrato;

				// validacion correo

				try {
					await validateExistenciaCorreo(correo);

					setFormValues({
						...formValues,
						folioContrato: folioContratoCliente,
						nombre: capitalizeString(`${nombre} ${apellidoPaterno} ${apellidoMaterno}`),
						nombreCompleto: nombre,
						apellidos: `${apellidoPaterno} ${apellidoMaterno}`,
						correo,
						idLicencia,
						cantidadEstudiantes,
					});

					setValidacion({
						...validacion,
						correo: true,
					});
				} catch (error) {
					console.log("Error validando el correo de registro inicial: ", error);

					setFormValues({
						...formValues,
						folioContrato: folioContratoCliente,
						nombre: capitalizeString(`${nombre} ${apellidoPaterno} ${apellidoMaterno}`),
						nombreCompleto: nombre,
						apellidos: `${apellidoPaterno} ${apellidoMaterno}`,
						correo: "",
						idLicencia,
						cantidadEstudiantes,
					});

					setActivarCorreoAlternativo(true);
				}

				const { data: licencia } = await getLicencia(idLicencia);

				setDatosLicencia(licencia);

				setShowLoader(!showLoader);
			} catch (error) {
				console.log("Error al consultar los datos del cliente: ", error);

				//! IMPLEMENTAR UNA ALERTA PARA EL USUARIO CON EL ERROR

				setError(true);

				setErrorReason(error.message);

				setShowLoader(!showLoader);
			}
		};

		const validarExistenciaFolio = async (folioContratoAValidar, tokenAcceso) => {
			try {
				await validateExistenciaFolioContrato(folioContratoAValidar);

				await validarTokenFolio(folioContrato, tokenAcceso);

				consultarDatosCliente(folioContratoAValidar);
			} catch (error) {
				console.log("Error al validar la existencia del folio: ", error);

				if (error.data && typeof error.data === "string" && error.data === "folio_registrado") {
					return history.replace(dashboardRoutesPaths.login);
				}

				setError(true);

				setErrorReason(error.message);

				setShowLoader(!showLoader);
			}
		};

		const validarTokenFolio = async (folioContrato, accessToken) => {
			return new Promise(async (resolve, reject) => {
				try {
					const token = await validateTokenFolio(folioContrato);

					// validar primero la existencia del folio en la plataforma, si existe mostrar error
					// si no existe, validar token y folio

					if (accessToken !== token) {
						return reject({
							message: "No se pudo validar tu registro, ingresa desde el enlace que se te hizo llegar vía correo electrónico. Si el problema persiste, comunícate con Soporte Técnico.",
						});
					}

					resolve(true);
				} catch (error) {
					console.log("Error al consultar el token del folio: ", error);

					// mostrar pantalla de error

					setError(true);

					setErrorReason(error.message);

					setShowLoader(!showLoader);
				}
			});
		};

		validarExistenciaFolio(folioContrato, token);
	}, []);

	const particlesInit = useCallback(async (engine) => {
		await loadFull(engine);
	}, []);

	const showRegistroSegundoUsuarioModal = () => {
		ModalSweetAlert({
			icon: "info",
			title: "Primero completa tu registro",
			text: "Para registrar a tu segundo usuario, deberás ingresar a la plataforma. Para esto, primero finaliza con tu registro e inicia sesión con la información que ingreses aquí.",
		});
	};

	const showAvisoPrivacidad = () => {
		ModalSweetAlert({
			title: "Aviso de privacidad",
			html: <AvisoPrivacidad />,
		});
	};

	const handleCorreoNombreUsuario = (event) => {
		try {
			const checked = event.target.checked;

			setValidacion({
				...validacion,
				nombreUsuario: checked,
			});

			let nombreUsuario = "";

			if (checked) {
				nombreUsuario = formValues.correo;
			}

			setFormValues({
				...formValues,
				nombreUsuario,
			});
		} catch (error) {
			console.log("error estableciendo el correo como nombre de usuario: ", error);
		}
	};

	const darkTheme = createTheme({
		palette: {
			mode: "dark",
		},
	});

	return (
		<>
			{showLoader ? (
				<Loader height={"80vh"} setShowLoader={setShowLoader} />
			) : (
				<>
					{!error && (
						<ThemeProvider theme={darkTheme}>
							<CssBaseline />

							<Particles options={particlesOptions} init={particlesInit} id="tsparticles" />

							<Animated animationIn="fadeIn" isVisible={true}>
								<div id="particles" className="container-form-registro">
									<Navbar style={{ backgroundColor: "transparent!important" }}>
										<Navbar.Brand href="#home">
											<Image src={require("@assets/images/logo-gc-horizontal.png")} width={150} />
											{/* <img alt="" src="/logo.svg" width="30" height="30" className="d-inline-block align-top" /> React Bootstrap */}
										</Navbar.Brand>
									</Navbar>

									<Container className="pb-4">
										<Row>
											<Col>
												<Card body className="card-form-registro ">
													<Container id="container-card" className="px-lg-0">
														<Row className="d-flex align-items-stretch">
															<Col xs={12} lg={5} xl={4} className="px-sm-0">
																<div className="img-bg-form-registro"></div>
																{/* <Image src={require("@assets/images/bg-formulario-registro.jpeg")} fluid /> */}
															</Col>

															<Col xs={12} lg={7} xl={8} className="ps-lg-0">
																<Form className="form-registro-inicial p-2" onSubmit={handleOnSubmit}>
																	<Container fluid className="pt-3">
																		<TitleDashboard title="Datos Personales" />

																		<Row className="pt-3">
																			<Form.Group as={Col}>
																				<Form.Label>Folio de Contrato</Form.Label>

																				<Form.Control readOnly type="text" placeholder="" name="folioContrato" value={formValues.folioContrato} />
																			</Form.Group>

																			<Form.Group as={Col}>
																				<Form.Label>Nombre</Form.Label>

																				<Form.Control readOnly type="text" name="nombre" value={formValues.nombre} />
																			</Form.Group>
																		</Row>

																		<Row>
																			<Form.Group as={Col}>
																				<Form.Label>CURP</Form.Label>

																				<Form.Control
																					className={validacion.curp ? "is-valid" : "is-invalid"}
																					name="curp"
																					type="text"
																					value={formValues.curp.trim()}
																					placeholder="Ingresa tu CURP"
																					maxLength="18"
																					onChange={handleOnChange}
																				/>

																				<Form.Control.Feedback type="invalid">
																					{formValues.curp === "" ? "Ingresa tu CURP" : "Este CURP ya se encuentra registrado, verifica la información o contacta a Soporte Técnico"}
																				</Form.Control.Feedback>
																			</Form.Group>

																			<Form.Group as={Col}>
																				<Form.Label>Correo Electrónico</Form.Label>

																				<Form.Control
																					className={validacion.correo ? "is-valid" : "is-invalid"}
																					name="correo"
																					type="text"
																					value={formValues.correo.trim()}
																					placeholder="Ingresa tu correo"
																					onChange={handleOnChange}
																					disabled={!activarCorreoAlternativo}
																				/>

																				<Form.Control.Feedback type="invalid">
																					{formValues.correo === "" ? "Ingresa tu correo" : "Este correo ya se encuentra registrado, verifica la información o contacta a Soporte Técnico"}
																				</Form.Control.Feedback>
																			</Form.Group>
																		</Row>

																		<Row className="pt-3 pb-4">
																			<Form.Group as={Col}>
																				<Form.Label>Alias</Form.Label>

																				<Form.Control
																					className={validacion.alias ? "is-valid" : "is-invalid"}
																					type="text"
																					placeholder="Ingresa un alias"
																					name="alias"
																					value={formValues.alias.trim()}
																					onChange={handleOnChange}
																				/>

																				<Form.Control.Feedback type="invalid">
																					{formValues.alias === "" ? "Ingresa un alias" : "Este alias ya se encuentra en uso, por favor elige otro"}
																				</Form.Control.Feedback>

																				<Form.Text className="text-muted">Este será el nombre con el cual te verán otras personas de la plataforma en el Foro de Ayuda</Form.Text>
																			</Form.Group>
																		</Row>

																		<TitleDashboard title="Datos de Ingreso" />

																		<Row className="pt-3">
																			<Form.Group as={Col}>
																				<Form.Label>Nombre de Usuario</Form.Label>

																				<FormGroup>
																					<FormControlLabel control={<Checkbox onChange={handleCorreoNombreUsuario} />} label="Usar correo electrónico" />
																				</FormGroup>

																				<Form.Control
																					className={validacion.nombreUsuario ? "is-valid" : "is-invalid"}
																					type="text"
																					placeholder="Ingresa un nombre de usuario"
																					name="nombreUsuario"
																					value={formValues.nombreUsuario.trim()}
																					onChange={handleOnChange}
																				/>

																				<Form.Control.Feedback type="invalid">{formValues.nombreUsuario === "" ? "Ingresa un nombre de usuario" : null}</Form.Control.Feedback>

																				<Form.Text className="text-muted">Este dato será necesario para cada vez que inicies sesión</Form.Text>

																				<br />

																				<Form.Text className="text-muted">
																					No debe contener:
																					<ul>
																						<li className={validacion.caracteresEspecialesNombreUsuario ? "text-success" : "text-danger"}>Caracteres especiales</li>
																						<li className={validacion.espaciosNombreUsuario ? "text-success" : "text-danger"}>Espacios</li>
																					</ul>
																				</Form.Text>
																			</Form.Group>
																		</Row>

																		<Row>
																			<Form.Group as={Col} md={6}>
																				<Form.Label>Contraseña</Form.Label>

																				<InputGroup hasValidation>
																					<InputGroup.Text id="inputGroupPrepend2" onClick={handleShowPasswords} className="pointer">
																						<FontAwesomeIcon icon={faEye} />
																					</InputGroup.Text>

																					<Form.Control
																						className={validacion.contrasenia ? "is-valid" : "is-invalid"}
																						name="contrasenia"
																						value={formValues.contrasenia.trim()}
																						type={showPasswords ? "text" : "password"}
																						placeholder="Ingresa una contraseña"
																						onChange={handleOnChangePasswords}
																						autoComplete="off"
																						ref={inputContrasenia}
																					/>

																					<Form.Control.Feedback type="invalid">Genera una contraseña v&aacute;lida</Form.Control.Feedback>

																					<CapsLockTooltip input={inputContrasenia} isCapsLockOn={isCapsLockOn} />
																				</InputGroup>

																				<Form.Text id="passwordHelpBlock" muted>
																					La contraseña debe contener:
																					<ul>
																						<li className={validacion.caracteresContrasenia ? "text-success" : "text-danger"}>8 caracteres</li>
																						<li className={validacion.mayusculaContrasenia ? "text-success" : "text-danger"}>1 mayúscula</li>
																						<li className={validacion.numeroContrasenia ? "text-success" : "text-danger"}>1 número</li>
																					</ul>
																					{/* La contraseña debe ser de al menos 8 caracteres, 1 mayúscula y 1 número */}
																				</Form.Text>
																			</Form.Group>

																			<Form.Group as={Col} md={6}>
																				<Form.Label>Confirma la contraseña</Form.Label>

																				<InputGroup hasValidation>
																					<InputGroup.Text id="inputGroupPrepend2" onClick={handleShowPasswords} className="pointer">
																						<FontAwesomeIcon icon={faEye} />
																					</InputGroup.Text>

																					<Form.Control
																						className={validacion.confirmacionContrasenia ? "is-valid" : "is-invalid"}
																						name="confirmacionContrasenia"
																						value={formValues.confirmacionContrasenia.trim()}
																						type={showPasswords ? "text" : "password"}
																						placeholder="Confirma tu contraseña"
																						onChange={handleOnChangePasswords}
																						autoComplete="off"
																					/>

																					<Form.Control.Feedback type="invalid">Las contraseñas no coinciden</Form.Control.Feedback>
																				</InputGroup>

																				<Form.Text id="passwordHelpBlock2" muted>
																					La contraseña debe ser igual a la anterior
																				</Form.Text>
																			</Form.Group>
																		</Row>

																		<Row>
																			<Col>
																				<span className="registro-segundo-usuario" onClick={showRegistroSegundoUsuarioModal}>
																					¿Cómo registrar al 2° Usuario?
																				</span>
																			</Col>

																			<Col>
																				<span className="registro-segundo-usuario" onClick={showManualUsuario}>
																					Manual de Usuario
																				</span>
																			</Col>
																		</Row>

																		<Row className="pt-3">
																			<Col>
																				<div className="d-grid gap-2">
																					<Button variant="success" type="submit" className="mb-4">
																						{loading ? "Registrando..." : "Completar registro"}
																					</Button>
																				</div>

																				<Form.Text muted>
																					Al <b>Completar tu registro</b>, estás aceptando y te comprometes a cumplir con nuestros <a href="https://globalclickmexico.com/terminos-condiciones/Terminos_condiciones.pdf" target="_blank">Términos y Condiciones</a> y nuestro{" "}
																					<a href="#" onClick={showAvisoPrivacidad}>
																						Aviso de Privacidad
																					</a>
																					.
																				</Form.Text>
																			</Col>
																		</Row>
																	</Container>
																</Form>
															</Col>
														</Row>
													</Container>
												</Card>
											</Col>
										</Row>
									</Container>
								</div>
							</Animated>

							<Modal size="lg" show={lgShow} onHide={() => setLgShow(false)} aria-labelledby="example-modal-sizes-title-lg" dialogClassName="modal-90h">
								<Modal.Header closeButton>
									<Modal.Title id="example-modal-sizes-title-lg">Manual de Usuario</Modal.Title>
								</Modal.Header>

								<Modal.Body>
									<iframe
										id="iframePDF"
										width="100%"
										height="100%"
										// style="border: none;"
										allow="autoplay; encrypted-media;fullscreen"
										loading="lazy"
										title="Lección actual"
										className="embed-responsive-item"
										// src={urlManual}
										src={datosLicencia.licenciaEmpresarial ? urlsManuales.asesor : urlsManuales.estudiante}></iframe>
								</Modal.Body>
							</Modal>
						</ThemeProvider>
					)}

					{error && <ErrorValidacionDatosRegistroInicial reason={errorReason} />}
				</>
			)}
		</>
	);
};

export default FormularioRegistroInicial;
