import React, { useContext } from "react";

import DashboardAulaVirtual from "@components/administrador/DashboardAulaVirtual/DashboardAulaVirtual";
import DashboardBancoPreguntas from "@components/administrador/DashboardBancoPreguntas/DashboardBancoPreguntas";
import { DashboardConsejos } from "@components/administrador/DashboardConsejos/DashboardConsejos";
import { DashboardGestionContenidoCursos } from "@components/administrador/DashboardGestionContenidoCursos/DashboardGestionContenidoCursos";
import DashboardLicencias from "@components/administrador/DashboardLicencias/DashboardLicencias";
import { DashboardNotificaciones } from "@components/administrador/DashboardNotificaciones/DashboardNotificaciones";
import { DashboardRutasAprendizaje } from "@components/administrador/DashboardRutasAprendizaje/DashboardRutasAprendizaje";
import { DashboardUsuarios } from "@components/administrador/DashboardUsuarios/DashboardUsuarios";
import MultiViewPanelAdministracionContext from "@context/MultiViewPanelAdministracion/MultiViewPanelAdministracionContext";
import multiViewPanelAdministracionPaths from "@helpers/multiViewPanelAdministracionPaths/multiViewPanelAdministracionPaths";
import { DashboardForoAyuda } from "@components/administrador/DashboardForoAyuda/DashboardForoAyuda";
import DashboardLeccionesEmpresariales from "@components/administrador/DashboardLeccionesEmpresariales/DashboardLeccionesEmpresariales";
import DashboardRegistroUsuarioInvitado from "@components/administrador/DashboardRegistroUsuarioInvitado/DashboardRegistroUsuarioInvitado";
import DashboardApariencia from "@components/administrador/DashboardApariencia/DashboardApariencia";
import DashboardPlantillasTrabajo from "@components/administrador/DashboardPlantillasTrabajo/DashboardPlantillasTrabajo";
import DashboardNuevoEstudiante from "@components/administrador/DashboardNuevoEstudiante/DashboardNuevoEstudiante";
import DashboardEstadisticas from "@components/administrador/DashboardEstadisticas/DashboardEstadisticas";
import DashboardAgendasAsesorias from "@components/administrador/DashboardAgendasAsesorias/DashboardAgendasAsesorias";

const MultiViewPanelAdministracion = ({ handleChangeComponent, setTemaSeleccionado }) => {
	const { contextValue, setContextValue } = useContext(MultiViewPanelAdministracionContext);

	const { view } = contextValue;

	return (
		<>
			{/* Dashboard Aula Virtual */}
			{view === multiViewPanelAdministracionPaths.dashboardAulaVirtual && <DashboardAulaVirtual />}

			{/* Dashboard Gestión de contenido de curso */}
			{view === multiViewPanelAdministracionPaths.dashboardGestionContenidoCursos && <DashboardGestionContenidoCursos />}

			{/* Dashboard Consejos Rápidos */}
			{view === multiViewPanelAdministracionPaths.dashboardConsejosRapidos && <DashboardConsejos />}

			{/* Dashboard Banco de preguntas */}
			{view === multiViewPanelAdministracionPaths.dashboardBancoPreguntas && <DashboardBancoPreguntas />}

			{/* Dashboard Licencias */}
			{view === multiViewPanelAdministracionPaths.dashboardLicencias && <DashboardLicencias />}

			{/* Dashboard Notificaciones */}
			{view === multiViewPanelAdministracionPaths.dashboardNotificaciones && <DashboardNotificaciones />}

			{/* Dashboard Rutas de Aprendizaje */}
			{view === multiViewPanelAdministracionPaths.dashboardRutasAprendizaje && <DashboardRutasAprendizaje />}

			{/* Dashboard Usuarios */}
			{view === multiViewPanelAdministracionPaths.dashboardUsuarios && <DashboardUsuarios handleChangeComponent={handleChangeComponent} />}

			{/* Dashboard Foro de ayuda */}
			{view === multiViewPanelAdministracionPaths.dashboardForoDeAyuda && <DashboardForoAyuda />}

			{/* Dashboard Foro de ayuda */}
			{view === multiViewPanelAdministracionPaths.dashboardLeccionesEmpresariales && <DashboardLeccionesEmpresariales />}

			{/* Dashboard Registro de usuarios capacitación */}
			{view === multiViewPanelAdministracionPaths.dashboardRegistroUsuarioInvitado && <DashboardRegistroUsuarioInvitado />}

			{/* Dashboard Para Apariencia */}
			{view === multiViewPanelAdministracionPaths.dashboardApariencia && <DashboardApariencia setTemaSeleccionado={setTemaSeleccionado} />}

			{/* Dahsboard Plantillas de Trabajo */}
			{view === multiViewPanelAdministracionPaths.dashboardPlantillasTrabajo && <DashboardPlantillasTrabajo />}

			{/* Dahsboard Registro nuevo estudiante */}
			{view === multiViewPanelAdministracionPaths.dashboardRegistroNuevoUsuario && <DashboardNuevoEstudiante />}

			{view === multiViewPanelAdministracionPaths.dashboardEstadisticasPlataforma && <DashboardEstadisticas />}

			{view === multiViewPanelAdministracionPaths.dashboardAgendasAsesorias && <DashboardAgendasAsesorias />}
		</>
	);
};

export default MultiViewPanelAdministracion;
