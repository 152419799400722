import { Notificacion } from "@components/general/Alertas/Alertas";
import UserContext from "@context/User/UserContext";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import {
  getLeccionesCurso,
  getUnidadesCurso,
} from "@helpers/httpRequests/httpRequestCursoIngles/httpRequestCursoIngles";
import {
  addNuevaLeccionVista,
  getLeccionesVistas,
  removeLeccionVista,
} from "@helpers/httpRequests/httpRequestLeccionesVistas/httpRequestsLeccionesVistas";
import { addUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

const DespliegeUnidadesLecciones = ({
  codigoCurso,
  setUrlLeccion,
  verificarEvaluacionLeccion,
  setLeccionActual,
  setShowEvaluacionLeccion,
  leccionActual,
  handleScrollToTop,
  idPerfil,
  setShowBtnEvaluacion,
}) => {
  //*States para el componente

  const [unidades, setUnidades] = useState([]);

  const [lecciones, setLecciones] = useState([]);

  const { contextValue, setContextValue } = useContext(UserContext);

  const { codigoSeccionSeleccionada, ultimoCursoTomado, usuarioLogeado } =
    contextValue;

  const { correo } = usuarioLogeado;

  const [leccionesVistas, setLeccionesVistas] = useState([]);

  //TODO Crear correctamente las url staticas e insertarlas en el orden correcto de la plantilla
  //TODO Reciclar el componente php de avances del curso y adecuar los avances a la seccion de Mis avances para ver desde ahi tambien.\
  //TODO El componente que se va a reciclar hay que darle una mejor presentacion si se usará igual que en la plataforma anterior.

  //*Funciones del componente

  // const handleSeleccionLeccion = async ({ currentTarget }) => {
  // 	try {

  // 		const codigoSeccionSeleccionada = currentTarget.id.substring(6, 0)

  // 		setContextValue({
  // 			...contextValue,
  // 			ultimaLeccionTomada:  currentTarget.id,
  // 			codigoSeccionSeleccionada
  // 		})

  // 			// console.log("Se Registra la ultima leccion desde handleSeleccionLeccion");
  // 			//*Update del campo 'idUltimaLeccionTomada'
  // 			const infoLeccion = {
  // 				idPerfil: idPerfil,
  // 				codigoLeccion: currentTarget.id,
  // 				fecha: getUnixTimestamp()
  // 			}
  // 			const { message } = await addUltimaLeccionTomada(infoLeccion);

  // 		let urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/ingles/url/index.php?modulo=ING1&email=${nombreUsuario}&leccion=${currentTarget.id}`;

  //         console.log(urlLeccionSeleccionada);

  // 		setShowBtnEvaluacion(true)
  // 		setUrlLeccion(urlLeccionSeleccionada);
  // 		setLeccionActual(currentTarget.id); //*currentTarget.id contiene la leccion seleccionada
  // 		setShowEvaluacionLeccion(false);
  // 		handleScrollToTop();
  // 		verificarEvaluacionLeccion(currentTarget.id);

  // 	} catch (error) {
  // 		console.log(error);
  // 	}
  // };

  const obtenerLeccionesVistas = async () => {
    try {
      // console.log("Se obtienen las lecciones por segunda vez");

      const { data } = await getLeccionesVistas(idPerfil);

      if (Array.isArray(data)) {
        setLeccionesVistas(data);
      } else {
        setLeccionesVistas([data]);
      }
    } catch (error) {
      console.log(error);
      setLeccionesVistas([]);
    }
  };

  const handleLeccionVista = async ({ target }) => {
    const { name, value } = target;

    const resultado = leccionesVistas.some(
      (leccionVista) => leccionVista.codigoLeccion === value
    );

    if (!resultado) {
      try {
        const infoLeccion = {
          idPerfil: idPerfil,
          codigoCurso: codigoCurso,
          codigoLeccion: value,
          fecha: getUnixTimestamp(),
        };

        await addNuevaLeccionVista(infoLeccion);

        obtenerLeccionesVistas();
      } catch (error) {
        console.log(error);
        Notificacion("error", "Error al guardar tu avance");
      }
    } else {
      // console.log("ya existe el valor en el objeto")
      //Aqui el valor si ya existe se eliminara del objeto

      try {
        const infoLeccion = {
          idPerfil: idPerfil,
          codigoLeccion: value,
        };
        const remove = await removeLeccionVista(infoLeccion);

        obtenerLeccionesVistas();

        // console.log(remove);
        for (var i = 0; i < leccionesVistas.length; i++) {
          if (leccionesVistas[i].codigoLeccion === value) {
            leccionesVistas.splice(i, 1);
            i--; // actualiza el índice después de eliminar un elemento
          }
        }

        // console.log(leccionesVistas);
      } catch (error) {
        console.log(error);
        Notificacion("error", "Error al modificar tu avance");
      }
    }
  };

  const handleAddUltimaLeccion = async ({ currentTarget }) => {
    try {
      const { id } = currentTarget;
      const infoLeccion = {
        idPerfil: idPerfil,
        codigoLeccion: id,
        fecha: getUnixTimestamp(),
      };
      // console.log("Ultima leccion dedsde urlActual");
      const { message } = await addUltimaLeccionTomada(infoLeccion);

      setLeccionActual(id);
    } catch (error) {
      console.log(error);
    }
  };

  //*Use effect para las consultas de los datos
  useEffect(() => {
    const getUnidades = async () => {
      const { data } = await getUnidadesCurso(codigoCurso);
      if (Array.isArray(data)) {
        setUnidades(data);
      } else {
        setUnidades([data]);
      }
    };

    getUnidades();

    const getLecciones = async () => {
      const { data } = await getLeccionesCurso(codigoCurso);
      console.log(data);
      if (Array.isArray(data)) {
        setLecciones(data);
      } else {
        setLecciones([data]);
      }
    };

    getLecciones();

    obtenerLeccionesVistas();
  }, []);

  return (
    <Accordion activeIndex={false}>
      {unidades.map((unidad, idx) => (
        <AccordionTab
          key={idx}
          header={
            unidad.codigoUnidad === leccionActual.substring(0, 6) ? (
              <b className="seccion-actual">{unidad.nombre} - Sección Actual</b>
            ) : (
              <h6>{unidad.nombre}</h6>
            )
          }
        >
          <Card.Body style={{ padding: "0" }}>
            <Container>
              <Row>
                <Col>
                  {lecciones.map(
                    (leccion, idy) =>
                      leccion.unidad === idx + 1 && (
                        <div
                          style={{
                            backgroundColor:
                              leccion.claseLeccion === leccionActual
                                ? "orange"
                                : "#fff",
                          }}
                          className="d-flex align-items-center justify-content-between p-2"
                        >
                          {leccion.claseLeccion.startsWith("VI", 6) && (
                            <p>
                              {/* <a href={`https://virtual.globalclickmexico.com/lecciones/ingles/url/index.php?modulo=ING1&email=${nombreUsuario}&leccion=${leccion.claseLeccion}`} target='_blank'>{leccion.nombre}</a> */}
                              <a
                                onClick={handleAddUltimaLeccion}
                                id={leccion.claseLeccion}
                                className="text-black none-decoration-text"
                                href={`https://virtual.globalclickmexico.com/lecciones/ingles/leccionesInitialVideo.php?modulo=ING1&email=${correo}&leccion=${leccion.claseLeccion}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h6>
                                  <b>
                                    {idy + 1}. {leccion.nombre}
                                  </b>
                                </h6>
                              </a>
                            </p>
                          )}

                          {leccion.codigoLeccion !== "VI" &&
                            leccion.codigoLeccion !== "VM" &&
                            leccion.codigoLeccion !== "RE" &&
                            leccion.codigoLeccion !== "PL" &&
                            leccion.codigoLeccion !== "VR" &&
                            leccion.codigoLeccion !== "VS" &&
                            leccion.codigoLeccion !== "PS" &&
                            leccion.codigoLeccion !== "EU" && (
                              <p>
                                <a
                                  onClick={handleAddUltimaLeccion}
                                  id={leccion.claseLeccion}
                                  className="text-black none-decoration-text"
                                  href={`https://virtual.globalclickmexico.com/lecciones/ingles/url/index.php?modulo=ING1&email=${correo}&leccion=${leccion.claseLeccion}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <h6>
                                    <b>
                                      {idy + 1}. {leccion.nombre}
                                    </b>
                                  </h6>
                                </a>
                              </p>
                            )}

                          {leccion.claseLeccion.startsWith("VM", 6) && (
                            <p>
                              {/* <a href={`https://virtual.globalclickmexico.com/lecciones/ingles/url/index.php?modulo=ING1&email=${nombreUsuario}&leccion=${leccion.claseLeccion}`} target='_blank'>{leccion.nombre}</a> */}
                              <a
                                onClick={handleAddUltimaLeccion}
                                id={leccion.claseLeccion}
                                className="text-black none-decoration-text"
                                href={`https://virtual.globalclickmexico.com/lecciones/ingles/leccionesVocabularyOfUnit.php?modulo=ING1&email=${correo}&leccion=${leccion.claseLeccion}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h6>
                                  <b>
                                    {idy + 1}. {leccion.nombre}
                                  </b>
                                </h6>
                              </a>
                            </p>
                          )}

                          {leccion.claseLeccion.startsWith("RE", 6) && (
                            <p>
                              {/* <a href={`https://virtual.globalclickmexico.com/lecciones/ingles/url/index.php?modulo=ING1&email=${nombreUsuario}&leccion=${leccion.claseLeccion}`} target='_blank'>{leccion.nombre}</a> */}
                              <a
                                onClick={handleAddUltimaLeccion}
                                id={leccion.claseLeccion}
                                className="text-black none-decoration-text"
                                href={`https://virtual.globalclickmexico.com/lecciones/ingles/leccionesReview.php?modulo=${codigoCurso}&email=${correo}&leccion=${leccion.claseLeccion}&p=0`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h6>
                                  <b>
                                    {idy + 1}. {leccion.nombre}
                                  </b>
                                </h6>
                              </a>
                            </p>
                          )}

                          {leccion.codigoLeccion === "PL" && (
                            <p>
                              <a
                                onClick={handleAddUltimaLeccion}
                                id={leccion.claseLeccion}
                                className="text-black none-decoration-text"
                                href={`https://virtual.globalclickmexico.com/lecciones/ingles/leccionesPracticeListening.php?modulo=${codigoCurso}&email=${correo}&leccion=${leccion.claseLeccion}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h6>
                                  <b>
                                    {idy + 1}. {leccion.nombre}
                                  </b>
                                </h6>
                              </a>
                            </p>
                          )}

                          {leccion.codigoLeccion === "VR" && (
                            <p>
                              <a
                                onClick={handleAddUltimaLeccion}
                                id={leccion.claseLeccion}
                                className="text-black none-decoration-text"
                                href={`https://virtual.globalclickmexico.com/lecciones/ingles/leccionesPracticeVideoReview.php?modulo=${codigoCurso}&email=${correo}&leccion=${leccion.claseLeccion}&p=0`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h6>
                                  <b>
                                    {idy + 1}. {leccion.nombre}
                                  </b>
                                </h6>
                              </a>
                            </p>
                          )}

                          {leccion.codigoLeccion === "VS" && (
                            <p>
                              <a
                                onClick={handleAddUltimaLeccion}
                                id={leccion.claseLeccion}
                                className="text-black none-decoration-text"
                                href={`https://virtual.globalclickmexico.com/lecciones/ingles/leccionesPracticeVideoReviewSub.php?modulo=${codigoCurso}&email=${correo}&leccion=${leccion.claseLeccion}&p=0`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h6>
                                  <b>
                                    {idy + 1}. {leccion.nombre}
                                  </b>
                                </h6>
                              </a>
                            </p>
                          )}

                          {leccion.codigoLeccion === "PS" && (
                            <p>
                              <a
                                onClick={handleAddUltimaLeccion}
                                id={leccion.claseLeccion}
                                className="text-black none-decoration-text"
                                href={`https://virtual.globalclickmexico.com/lecciones/ingles/leccionesPracticeSpeaking.php?modulo=${codigoCurso}&email=${correo}&leccion=${leccion.claseLeccion}&p=0`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h6>
                                  <b>
                                    {idy + 1}. {leccion.nombre}
                                  </b>
                                </h6>
                              </a>
                            </p>
                          )}

                          {leccion.claseLeccion.startsWith("EU", 6) && (
                            <p>
                              {/* <a href={`https://virtual.globalclickmexico.com/lecciones/ingles/url/index.php?modulo=ING1&email=${nombreUsuario}&leccion=${leccion.claseLeccion}`} target='_blank'>{leccion.nombre}</a> */}
                              <a
                                onClick={handleAddUltimaLeccion}
                                id={leccion.claseLeccion}
                                className="text-black none-decoration-text"
                                href={`https://virtual.globalclickmexico.com/lecciones/ingles/leccionesEvalUnidad.php?modulo=${codigoCurso}&email=${correo}&leccion=${leccion.claseLeccion}&p=0`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h6>
                                  <b>
                                    {idy + 1}. {leccion.nombre}
                                  </b>
                                </h6>
                              </a>
                            </p>
                          )}

                          <OverlayTrigger
                            className="overlay-trigger"
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-bottom}`}>
                                {leccionesVistas.some(
                                  (leccionVista) =>
                                    leccionVista.codigoLeccion ===
                                    leccion.claseLeccion
                                )
                                  ? "Marcar como no completado"
                                  : "Marcar como completado"}
                              </Tooltip>
                            }
                          >
                            <Checkbox
                              id={idx + 1}
                              name={leccion.claseLeccion}
                              value={leccion.claseLeccion}
                              className="mx-3"
                              onChange={handleLeccionVista}
                              checked={leccionesVistas.some(
                                (leccionVista) =>
                                  leccionVista.codigoLeccion ===
                                  leccion.claseLeccion
                              )}
                            ></Checkbox>
                          </OverlayTrigger>
                        </div>
                      )
                  )}
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </AccordionTab>
      ))}
    </Accordion>
  );
};

export default DespliegeUnidadesLecciones;
