import { Notificacion } from "@components/general/Alertas/Alertas";
import { updateColaborador } from "@helpers/httpRequests/httpRequestsUsuarios/empleados/httpRequestsEmpleados";
import React from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const ModificarUsuario = ({ colaborador, setEmpleadoModificado }) => {
  // console.log(colaborador);

  const { nombreCompleto, apellidos, curp, profesion, idPersona } = colaborador;

  const [formValues, setFormValues] = useState({
    nombre: nombreCompleto,
    apellidos,
    curp,
    profesion,
  });

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const modificarUsuario = async (e) => {
    e.preventDefault();
    try {
      const { nombre, apellidos, curp, profesion } = formValues;

      const informacionEmpleado = {
        nombre,
        apellidos,
        curp,
        profesion,
        idPersona,
      };

      // console.log(informacionEmpleado);

      const { message } = await updateColaborador(informacionEmpleado);

      // console.log(message);
      setEmpleadoModificado(true);
      Notificacion("success", "Cambios guardados exitosamente");
    } catch (error) {
      console.log(error);
      Notificacion("error", "Hubo un error al guardar los cambios");
    }
  };

  return (
    <Container className="text-start">
      <Row>
        <Col>
          <Form onSubmit={modificarUsuario}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                required
                name="nombre"
                value={formValues.nombre}
                type="text"
                placeholder="Nombre del usuario"
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Apellidos</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                required
                name="apellidos"
                value={formValues.apellidos}
                type="text"
                placeholder="Apellidos del usuario"
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>CURP</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                required
                name="curp"
                value={formValues.curp}
                type="text"
                placeholder="CURP"
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Profesión</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                required
                name="profesion"
                value={formValues.profesion}
                type="text"
                placeholder="Profesión del usuario"
              ></Form.Control>
            </Form.Group>

            <Button type="submit" block>
              Guardar cambios
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ModificarUsuario;
