import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const enviarConfirmacionRegistro = (infoSecondUser) => {
	return new Promise(async (resolve, reject) => {
		try {
			const confirmacionRegistro = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.registro.segundoPerfil}`,
				method: "POST",
				body: JSON.stringify(infoSecondUser),
			});

			resolve(confirmacionRegistro);
		} catch (error) {
			reject(error);
		}
	});
};

export const enviarFormularioRecuperacionContrasenia = (datosFormulario) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: codigoEnviado } = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.solicitudFormularioCambioContrasenia}`,
				method: "POST",
				body: JSON.stringify(datosFormulario),
			});

			resolve(codigoEnviado);
		} catch (error) {
			// console.log('Error al enviar el código de verificación para el cambio de contraseña: ', error);

			reject(error);
		}
	});
};

export const enviarVerificacionAgendaMos = ({ correo, nombre }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: codigoEnviado } = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.solicitudCodigoVerificacionAgendaMos}`,
				method: "POST",
				body: JSON.stringify({ correo, nombre }),
			});

			resolve(codigoEnviado);
		} catch (error) {
			reject(error);
		}
	});
};

export const enviarRestablecimientoNip = ({ correo, nombre, idPerfil }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: codigoEnviado } = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.solicitudRestablecimientoNip}`,
				method: "POST",
				body: JSON.stringify({ correo, nombre, idPerfil }),
			});

			resolve(codigoEnviado);
		} catch (error) {
			// console.log('Error al enviar el código de verificación para el cambio de contraseña: ', error);

			reject(error);
		}
	});
};

export const enviarCodigoVerificacionNombreUsuario = ({ correo, nombre }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data: codigoEnviado } = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.solicitudCodigoVerificacionCambioNombreUsuario}`,
				method: "POST",
				body: JSON.stringify({ correo, nombre }),
			});

			resolve(codigoEnviado);
		} catch (error) {
			// console.log('Error al enviar el código de verificación para el cambio de contraseña: ', error);

			reject(error);
		}
	});
};

export const enviarSolicitudLlamadaVenta = ({ nombre, correo, numeroTelefono }) => {
	return new Promise(async (resolve, reject) => {
		try {
			await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.solicitudLlamadaVenta}`,
				method: "POST",
				body: JSON.stringify({
					nombre,
					correo,
					numeroTelefono,
				}),
			});

			resolve(true);
		} catch (error) {
			// console.log("Error al enviar la solicitud de llamada de venta: ", error);

			reject(error);
		}
	});
};

export const enviarComprobanteAbono = (datosComprobante) => {
	return new Promise(async (resolve, reject) => {
		try {
			const comprobanteEnviado = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.pagos.enviarComprobanteAbono}`,
				method: "POST",
				body: JSON.stringify(datosComprobante),
			});

			resolve(true);
		} catch (error) {
			// console.log("Error al enviar el comprobante de abono: ", error);

			reject(error);
		}
	});
};

export const enviarComprobanteLiquidacion = (datosComprobante) => {
	return new Promise(async (resolve, reject) => {
		try {
			const comprobanteEnviado = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.pagos.enviarComprobanteLiquidacion}`,
				method: "POST",
				body: JSON.stringify(datosComprobante),
			});

			resolve(true);
		} catch (error) {
			// console.log("Error al enviar el comprobante de liquidación: ", error);

			reject(error);
		}
	});
};

export const enviarComprobanteCompraCertificacionMOS = (datosComprobante) => {
	return new Promise(async (resolve, reject) => {
		try {
			const comprobanteCompraCertMos = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.pagos.enviarComprobanteCompraCertificacionMOS}`,
				method: "POST",
				body: JSON.stringify(datosComprobante),
			});

			resolve(comprobanteCompraCertMos);
		} catch (error) {
			reject(error);
		}
	});
};

export const enviarComprobanteCompra = (datosComprobante) => {
	return new Promise(async (resolve, reject) => {
		try {
			const comprobanteCompra = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.pagos.enviarComprobanteCompra}`,
				method: "POST",
				body: JSON.stringify(datosComprobante),
			});

			resolve(comprobanteCompra);
		} catch (error) {
			reject(error);
		}
	});
};

export const enviarComprobanteRenovacion = (datosComprobante) => {
	return new Promise(async (resolve, reject) => {
		try {
			const comprobanteCompra = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.pagos.enviarComprobanteRenovacion}`,
				method: "POST",
				body: JSON.stringify(datosComprobante),
			});

			resolve(comprobanteCompra);
		} catch (error) {
			reject(error);
		}
	});
};

export const enviarRecuperacionNombreUsuario = (datosCorreo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: codigoEnviado } = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.solicitudRecuperacionNombreUsuario}`,
				method: "POST",
				body: JSON.stringify(datosCorreo),
			});

			resolve(codigoEnviado);
    } catch (error) {
      reject(error);
    }
  });
};

export const solicitudRestablecimientoNombreUsuario = (datosCorreo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: codigoEnviado } = await httpRequest({
				url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.solicitudRestablecimientoNombreUsuario}`,
				method: "POST",
				body: JSON.stringify(datosCorreo),
			});

			resolve(codigoEnviado);
    } catch (error) {
      reject(error);
    }
  });
};


export const solicitudEstadoDeCuenta = (datosCorreo) => {
	return new Promise(async (resolve, reject) => {
	  try {
		const { data: codigoEnviado } = await httpRequest({
				  url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.estadosCuenta.solicitudEstadoCuenta}`,
				  method: "POST",
				  body: JSON.stringify(datosCorreo),
			  });
  
			  resolve(codigoEnviado);
	  } catch (error) {
		reject(error);
	  }
	});
  };
  

  export const confirmacionSolicitudEstadoCuenta = (datosCorreo) => {
	return new Promise(async (resolve, reject) => {
	  try {
		const { data: codigoEnviado } = await httpRequest({
				  url: `${endpointsPaths.mailers}/${httpRequestsPaths.mailers.estadosCuenta.confirmacionSolicitudEstadoCuenta}`,
				  method: "POST",
				  body: JSON.stringify(datosCorreo),
			  });
  
			  resolve(codigoEnviado);
	  } catch (error) {
		reject(error);
	  }
	});
  };
  